<template>
  <div class="productList">
    <div class="container">
      <div class="banner">
        <div class="auto-container">
          <h1>产品列表</h1>
          <p><a href="/">首页</a>
            <Icon
              class="arrow"
              type="ios-arrow-forward"
            />公司
            <Icon
              class="arrow"
              type="ios-arrow-forward"
            />产品列表
          </p>
        </div>
      </div>
      <div class="auto-container">
        <div class="info">
          <Tabs @on-click="setmenuId">
            <TabPane
              :label="item.title"
              v-for="(item, index) in navList"
              :key="index"
              :name="`${item.serId}`"
            >

            </TabPane>
          </Tabs>

          <Row :gutter="30">
            <Col
              :xl="12"
              :lg="12"
              :md="24"
              :sm="24"
              v-for="(item,index) in newList"
              :key="index"
            >
            <!-- 点击内容详情页 @click="toContant(item.code)" -->
            <div class="inner-box">
              <div class="content">
                <img
                  :src="item.wechatPic?item.wechatPic:defaultImg"
                  alt=""
                />
                <div>
                  <h3><a href="services-full.html">{{item.title}}</a></h3>
                  <div class="text">{{item.abst}}</div>
                </div>
              </div>
            </div>
            </Col>
          </Row>

          <!-- <Row :gutter="30">
            <Col
              span="8"
              v-for="(item,index) in newList"
              :key="index"
            >
            <div class="inner-box" @click="toContant(item.code)">
              <div class="image">
                <a href="#"><img
                    :src="item.wechatPic?item.wechatPic:defaultImg"
                    alt=""
                  /></a>
              </div>
              <div class="lower-content">
                <h3><a href="#">{{item.title}}</a></h3>
                <div class="text">{{item.text}}</div>
              </div>
            </div>
            </Col>
          </Row> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import product from "./product.js";
export default product;
</script>

<style lang="less" scoped>
@import "./product.less";
</style>