import axios from "axios"
export default {
    data() {
        return {
            weburl: "https://www.rayscloud.cn/webapi",
            defaultImg: require('../../assets/resource/news-1.jpg'),
            siteId: localStorage.getItem("siteId"),
            menuId: localStorage.getItem("menuId"),
            menu: {},
            childId: 0,
            navList: [],
            newList: [],
        }
    },
    methods: {
        setmenuId(name) {
            this.childId = name;
            localStorage.setItem("childId", this.childId);
            this.getchild2();
        },
        toContant(code) {
            let route = this.$router.resolve({
                path: `/supportContant?code=${code}`
            })
            window.open(route.href, '_blank');
        },
        getchild() {
            axios.get(`${this.weburl}/api/Site/Menu/list/${this.siteId}/${this.menuId}`)
                .then((res) => {
                    if (res.status == 200) {
                        this.navList = res.data.data;
                        if (this.navList.length > 0) {
                            this.childId = this.navList[0].serId;
                            this.getchild2();
                        }
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        },
        getchild2() {
            axios.get(`${this.weburl}/api/Site/Menu/${this.siteId}/${this.childId}`)
                .then((res) => {
                    if (res.status == 200) {
                        localStorage.setItem("childId", this.childId);
                        this.newList = res.data.data;
                        console.log(this.newList)
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        },
    },
    created() {
        this.getchild();
    },
}